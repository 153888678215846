import _ from 'lodash'
import { srGet } from '@sevenrooms/core/api'
import { EmailCampaignReservationsAdapter } from '../adapters'
import type { EmailCampaignReservationsApi } from '../models'

export abstract class EmailCampaignReservationsService {
  public static async getReservations({
    id,
    venueId,
    startDate,
    endDate,
  }: {
    id: string
    venueId: string
    startDate: string
    endDate: string
  }) {
    const response = await srGet<EmailCampaignReservationsApi>(
      `/api-yoa/email_campaigns/${id}/reservations`,
      _.omitBy({ venue: venueId, start_date: startDate, end_date: endDate }, _.isEmpty)
    )
    return { data: EmailCampaignReservationsAdapter.toClient(response.data) }
  }
}
