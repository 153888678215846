import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { EmailCampaignReservationsService, type EmailCampaignReservations } from '../api'

export const emailCampaignReservationsApi = createApi({
  reducerPath: 'marketing.emailCampaignReservations',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: ['EmailCampaignReservations'],
  endpoints: builder => ({
    getEmailCampaignReservations: builder.query<
      { data: EmailCampaignReservations },
      Parameters<typeof EmailCampaignReservationsService.getReservations>[0]
    >({
      queryFn: RTKUtils.rtkQuery(EmailCampaignReservationsService.getReservations),
      providesTags: ['EmailCampaignReservations'],
    }),
  }),
})

export const { useGetEmailCampaignReservationsQuery } = emailCampaignReservationsApi
