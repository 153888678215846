/* eslint-disable camelcase */
import type { AccessRuleApi, AvailabilityTimeslot, MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import type { CreditCardCollectionOptionsEnum } from '@sevenrooms/core/domain'
import type * as stripeJs from '@stripe/stripe-js'
import type { TransactionTypes } from 'mgr/lib/utils/Constants'
import type { AccountTypes } from 'svr/lib/Payments/Constants'

export interface RootState {
  bookPaymentState: BookPaymentState
}

export interface GlobalInit {
  venueTimezone: string
  venueSettings: {
    credit_card_collection: CreditCardCollectionOptionsEnum
    incomplete_paylink_auto_cancel_minutes_long: number
    incomplete_paylink_auto_cancel_minutes_short: number
    paylink_only: boolean
  }
}

export interface Transaction {
  id: string
  transaction_type: (typeof TransactionTypes)[keyof typeof TransactionTypes]
  paylink_auto_cancel_datetime: string
  paylink_auto_cancel_minutes?: number
  is_reservation_charge: boolean
  is_shift_required: boolean
  reservation_base_price: number // base (always positive number)
  base_amount_decimal: number // base + bundled upgrades (always positive number)
  upsell_amount_decimal: number // selectable upgrades (can be negative)
  amount_decimal: number // base + bundled upgrades + selectable upgrades (can be negative)
  amount?: number // formatted string?
  card_id: string
  email: string
  amount_remaining_decimal: number
  tax?: string
  reservation_tax?: number
  reservation_tax_group_id?: string
  is_adhoc: boolean
  is_charge: boolean
  is_info_request: boolean
  status: TransactionStatus
  gratuity?: string
  service_charge?: string
  notes?: string
  client_select_gratuity: boolean
  require_select_gratuity: boolean
  created_via_res_add_edit: boolean
  upsell_items?: {
    automatic_upsells?: Record<string, UpgradeInventoryTransaction>
    selected_upsells?: Record<string, UpgradeInventoryTransaction>
  }
}

export enum TransactionStatus {
  SUCCEEDED = 'status_succeeded',
  FAILED = 'status_failed',
}

export interface UpgradeInventoryTransaction {
  category_id: string
  is_price_hidden: boolean
  name: string
  pos_item_id: string
  price: number
  amount: number
  quantity?: number
  quantity_num?: number
  reservation_tags: string[]
  description: string
  promo_pricing: []
  gratuity: number
  gratuity_charge_amount: number
  service_charge_amount: number
  service_charge_percentage: number
  tax_amount: number
  tax_group_id: string
  tax_name: string
  tax_percentage: number
  quantity_equal_type?: string
}

export interface LoadedTransactionsPayload {
  transactionResult: { charges: Transaction[] }
}

export interface Actual {
  id: string
  access_persistent_id: string
  shift_persistent_id: string
  using_default_duration: boolean
  duration: number
  arrival_time_display: string
  arrival_time_sort_order: number
  date_arrival_time_dt_sync_dt_formatted: string
  access_rule: AccessRuleApi | null
  venue_group_client: { email_address: string; credit_cards: ResCardApi[] }
  additional_reservation_cards?: ResCardApi[]
  email_address: string
  phone_number_formatted?: string
  paylink_auto_cancel_minutes: number
  total_guests?: number
  booked_with_override: boolean
  booked_with_required_upgrade_override: boolean
  paylink_auto_cancel_datetime_local: string
  payments_card_id: string
  payments_last_four: string
  payments_card_type: string
  payments_email: string
  payments_card_fingerprint: string
  venue_seating_area_id: string | null
  upsell_snapshot?: {
    notes: string
    selected_categories?: Record<string, {}>
    selected_inventories?: Record<string, UpgradeInventoryActual>
  }
  date_moment: moment.Moment
  max_guests?: number
  client_name_with_display_order: string
}

export interface UpgradeInventoryActual {
  category_id: string
  is_price_hidden: boolean
  name: string
  pos_item_id: string
  price: number
  quantity: number
  reservation_tags: string[]
}

export type PaymentTypes = keyof typeof AccountTypes

export interface Venue {
  id: string
  accountId: string
  connectedSetupIntents: string
  paymentType: PaymentTypes
  permissions?: { canCharge?: boolean }
  bookSettings: { taxGroups: TaxGroup[] }
  currencyCode: string
  currencySymbol: string
  countryCode: string
}

export interface CopyViewToBookDetailsPayload {
  actual?: Actual
  venue?: Venue
  accountId?: string
  connectedSetupIntents?: string
}

export interface EnterAddOrEditReservationPayload {
  venue?: Venue
  accountId?: string
}

export interface Charges {
  currencyCode: string
  chargeType: AvailabilityTimeslot['charge_type']
  cardRequired: boolean
  paymentRule: AvailabilityTimeslot['cc_payment_rule']
  partySizeMinRule: AvailabilityTimeslot['cc_party_size_min']
  costMinRule: AvailabilityTimeslot['cost']
  requiredGratuity: AvailabilityTimeslot['gratuity']
  chargeApplyTax: boolean
  taxGroupId?: string
  chargeTax: number
  applyServiceCharge: boolean
  serviceCharge: number
  applyGratuityCharge: boolean
  gratuityType: AvailabilityTimeslot['gratuity_type']
  requiredGratuityCharge: boolean
  gratuityCharge: number
  paylinkGratuityType: GratuityType
}

export interface ChargeData {
  cardRequired: boolean
  currencyCode: string
  chargeApplyTax: boolean
  taxGroupId: string
  chargeTax: string
  applyServiceCharge: boolean
  serviceCharge: string
  applyGratuityCharge: boolean
  gratuityCharge: string
  partySizeMinRule: number
  paymentRule: PaymentRule
  costMinRule: number
  chargeType: string
}

export interface GetTimesSuccessPayload {
  venue: Venue
  availableTimes: string[]
  internalCutoff: string
  chargeData: ChargeData
  partySize: number
  duration: number
  previouslyChargedAmount?: number
  accountId: string
  connectedSetupIntents: string
}

export interface ResCard {
  resCardId: string
  resCardLast4: string
  resCardType: string
  resCardFingerprint?: string
  notificationEmail: string
}

export interface CardDetails {
  cardHolderName?: string
  cardHolderNumber?: string
  cardExpYear: string
  cardExpMonth: string
  cardCcv: string
  cardPhoneCountry: string
  cardPhoneNumber: string
}

export interface ResCardApi {
  billing_history_id: string
  card_id: string
  brand: string
  last_4: string
  last_four: string
  card_type: string
  card_fingerprint: string
  email: string
}

export interface DeleteResCardApi {
  payments_card_id: string
  payments_last_four: string
  payments_card_type: string
  payments_fingerprint: string
  payments_email: string
  additional_reservation_cards: ResCardApi[]
}

export interface Client {
  credit_cards: ResCardApi[]
  phone_number_formatted?: string
  phone_number_locale?: string
}

export interface TaxGroup {
  id: string
  tax_name: string
  tax_rate: number
}

export type CardEntryOption = 'manual' | 'paylink' | string | null

export interface ToggleModalChargePayload {
  value?: boolean
  restrict?: 'chargeOnly'
  card: ResCard
  paylinkOnly?: boolean
  canCharge?: boolean
  accountId: string
  connectedSetupIntents: string
}

export interface ChangeSelectedTimeslotPayload {
  selectedTimeSlot: MixedAvailabilityTimeslot
  isPreselectedTime: boolean
  venue: Venue
  accountId: string
  connectedSetupIntents: string
}

export type NotificationType = 'charge' | 'refund' | 'paylink'

export type TakeOrSave = 'take' | 'save' | 'none'

export type GratuityType = 'gratuity_percentage' | 'client_select_gratuity' | 'require_client_select_charge'
export type ChargeGratuityType = 'DEFAULT_GRATUITY' | 'SPECIFIC_GRATUITY' | 'CLIENT_GRATUITY'

export type RefundType = 'full' | 'partial'

export interface InitialReservationInfo {
  dateTime?: string
  chargeAmount?: string
  cardRequired?: boolean
  partySize?: number
}
export interface ChangedReservationInfo {
  datetime: boolean
  charges: boolean
  partySize: boolean
}

export interface SaferpayData {
  needClean?: boolean
}

export interface AdyenData {
  needClean?: boolean
}

export interface ExtendedStripeInstance extends stripeJs.Stripe {
  _key: string
}

export type PaymentRule = 'advanced_payment' | 'save_for_later'

export interface ClientSelectGratuityState {
  gratuity: number | null
  gratuityClientSelect: boolean
  requireGratuityCharge: boolean
  applyGratuity: boolean
}

export interface BookPaymentState {
  venueTimezone?: string
  creditCardCollection?: CreditCardCollectionOptionsEnum
  incompletePaylinkAutoCancelMinutesLong: number
  incompletePaylinkAutoCancelMinutesShort: number
  paylinkOnly?: boolean

  transactions?: Transaction[]
  accountId?: string
  connectedSetupIntents?: string
  actual?: Actual
  venue?: Venue
  outstandingPaylinkTransaction: Transaction | null
  outstandingPaylink: string | null

  taxGroups: TaxGroup[] | null

  formErrors: Record<string, string>

  currencyCode: string

  chargeAmount?: string
  formattedChargeAmount?: string
  chargeTotal?: string
  chargeApplyTax?: boolean
  taxGroupId?: string | null
  chargeTax?: string | null
  applyServiceCharge?: boolean
  serviceCharge?: string
  applyGratuityCharge?: boolean
  gratuityCharge?: string
  paylinkGratuityType?: GratuityType
  gratuityType?: ChargeGratuityType
  chargeDescription?: string
  serviceChargeAmount?: string
  taxAmount?: string
  gratuityChargeAmount?: string
  requiredGratuityCharge: boolean
  chargeType: string
  cardRequired: boolean
  paymentRule: PaymentRule | null
  partySizeMinRule: number
  costMinRule: number
  requiredGratuity: number
  notificationTransactionId: string | null
  chargeOnlyModal: boolean

  resCardId?: string
  resCardLast4?: string
  resCardType?: string
  resCardEmail?: string
  resCardFingerprint?: string
  notificationEmail?: string
  additionalReservationCards: ResCard[]

  isProcessingDelete: boolean

  deletingRequest: string | null

  refundingId?: string | null
  refundAmount?: string
  refundFull?: string

  clientCards: ResCardApi[] | null
  useGuestProfilePhoneNumber?: boolean

  override: boolean

  cardPhoneCountry?: string
  cardPhoneNumber?: string
  cardEntryOption?: CardEntryOption
  selectedCardId?: string | null

  expandedRows: string[]

  cardHolderName?: string
  cardHolderNumber?: string
  cardExpYear: string
  cardExpMonth: string
  cardCcv: string
  chargeAmountDiff: number

  notificationType?: NotificationType | ''
  isModalSubmitting: boolean
  chargeModalOpen: boolean
  notificationModalOpen: boolean

  takePaymentOrSave: TakeOrSave
  paylinkAutoCancel?: number | null
  chargeSendNotification?: boolean

  cardToken?: string
  cardData: string | null

  refundType?: RefundType
  refundDescription?: string
  refundSendNotification?: boolean
  refundGiftCardAmount?: boolean

  stripeCardElement: stripeJs.StripeCardElement | null

  initialReservationInfo?: InitialReservationInfo
  changedReservationInfo?: ChangedReservationInfo

  saferpayInitialData?: SaferpayData
  adyenInitialData?: AdyenData
  saferpayNameEmpty?: boolean
  stripeInstance: ExtendedStripeInstance | null
  paymentPublicToken: string

  isEditMode: boolean

  paylinkRemoveModalTransactionId: string | null

  clientSelectGratuity: ClientSelectGratuityState
}
