import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { EmailCampaignLinkPerformanceService, type EmailCampaignLinkPerformances } from '../api'

export const emailCampaignLinkPerformanceApi = createApi({
  reducerPath: 'marketing.emailCampaignLinkPerformance',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: ['EmailCampaignLinkPerformance'],
  endpoints: builder => ({
    getEmailCampaignLinkPerformance: builder.query<
      { data: EmailCampaignLinkPerformances },
      Parameters<typeof EmailCampaignLinkPerformanceService.getLinkPerformance>[0]
    >({
      queryFn: RTKUtils.rtkQuery(EmailCampaignLinkPerformanceService.getLinkPerformance),
      providesTags: ['EmailCampaignLinkPerformance'],
    }),
  }),
})

export const { useGetEmailCampaignLinkPerformanceQuery } = emailCampaignLinkPerformanceApi
