import _ from 'lodash'
import { srGet } from '@sevenrooms/core/api'
import { EmailCampaignLinkPerformanceAdapter } from '../adapters'
import type { EmailCampaignLinkPerformancesApi } from '../models'

export abstract class EmailCampaignLinkPerformanceService {
  public static async getLinkPerformance({ id, venueId }: { id: string; venueId: string }) {
    const response = await srGet<EmailCampaignLinkPerformancesApi>(
      `/api-yoa/email_campaigns/${id}/link_performance`,
      _.omitBy({ venue: venueId }, _.isUndefined)
    )
    return { data: EmailCampaignLinkPerformanceAdapter.toClient(response.data) }
  }
}
